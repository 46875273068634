<template>
  <diva-card>
    <div>
      <template v-if="loading && !getModel">
        <content-loader></content-loader>
      </template>
      <template v-else>
        <el-tabs @tab-click="tabhandler" v-model="activeTab" class="mb-4">
          <el-tab-pane :label="getTrans('messages.account')" name="model_account"></el-tab-pane>
          <el-tab-pane :label="getTrans('messages.profiles')" name="model_profiles"></el-tab-pane>
          <el-tab-pane :label="getTrans('messages.tokens')"  name="model_tokens"></el-tab-pane>
          <el-tab-pane :label="getTrans('messages.privacy')"  name="model_privacy"></el-tab-pane>
          <el-tab-pane :label="getTrans('messages.password')"  name="model_password"></el-tab-pane>
          <el-tab-pane :label="getTrans('messages.social')"  name="model_social"></el-tab-pane>
        </el-tabs>
        <router-view @save="saveModel" :user="getModel" v-if="getModel" :type="'studio'" :getError="getError"></router-view>
      </template>
    </div>
  </diva-card>
</template>

<script>
import { mapGetters } from "vuex";
import Errors from "@/mixins/Errors";
import {ElTabs, ElTabPane} from "element-plus";

export default {
  name: "ModelSettings",
  components: {
    [ElTabs.name]: ElTabs,
    [ElTabPane.name]: ElTabPane,
  },
  mixins: [Errors],
  props: {
      modelId: {
        type: [String, Number, Object],
        default: ()=> {
          return null;
        }
      }
  },
  data() {
    return {
      activeTab: 'model_account',
    };
  },
  computed: {
    ...mapGetters('Model', {getModel: 'getData', errors: 'getErrors', loading: 'loading'}),
  },
  methods: {
    tabhandler() {
      this.$router.push({name: this.activeTab});
    },
    saveModel(u) {
      u.id = this.modelId;
      this.$store.dispatch('Model/save', u).then((r)=>{
        this.$swal.fire(this.getTrans('messages.saved'),"" ,'success');
        return r
      }).catch((e)=>{
        this.$swal.fire(this.getTrans('messages.save_failed'),"" ,'error');
        return e
      });
    }
  },
  mounted() {
    this.$store.dispatch("Country/get");
    if (this.$route.name=='model_settings' || this.$route.name == 'model_account') {
      this.tabhandler();
    } else {
      this.activeTab = this.$route.name;
      //this.tabhandler();
    }
    if(!this.getModel) {
      this.$store.dispatch("Model/get", this.modelId);
    }
  }
};
</script>
